import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  List,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
  Removed,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="August 2018"
      subnav="release-notes">
      <div id="August2018" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          This month’s batch is small but mighty. A common theme is the
          deprecation of tint, shade and success colors in favor of text and
          confirmation—with <em>every</em> platform updated to comply. We also
          solved not one, but two tricks involving a dismiss. And what kind of
          release would it be without a few new icons? Complete details below.
        </Paragraph>

        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                Lots o’ colors to the{' '}
                <Link href="/resources/code-variables/deprecated-variables">
                  deprecated variables
                </Link>
                .
              </Added>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.17.2"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.17.2"
            />

            <div className={styles.changesList}>
              <Added>
                A dismiss button on the{' '}
                <Link href="/components/selects/single-select" isDesignCodeLink>
                  single select
                </Link>
                .
              </Added>
              <Added>
                <code>labelParent</code> for{' '}
                <Link href="/components/icon" isDesignCodeLink>
                  icon
                </Link>{' '}
                and <code>parent</code> for{' '}
                <Link href="/components/tooltip" isDesignCodeLink>
                  tooltip
                </Link>{' '}
                to better position elements in their given containers.
              </Added>
              <Added>
                A <code>hideOverflow</code> prop in all type components to
                truncate text with an ellipsis.
              </Added>
              <Fixed>All selects to make the entire input clickable.</Fixed>
              <Fixed>
                <Link href="/components/selects/lookup-select" isDesignCodeLink>
                  Lookup selects
                </Link>{' '}
                so the filter works even after an item is selected.
              </Fixed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="CSS"
              version="1.11.2"
              versionUrl="https://github.com/hudl/uniform-ui-css/releases/tag/v1.11.2"
            />

            <div className={styles.changesList}>
              <Fixed>
                Text on inputs to use{' '}
                <Link href="/guidelines/colors/utility" isDesignCodeLink>
                  utility colors
                </Link>
                .
              </Fixed>
              <Fixed>
                SVG checkboxes to stop resetting page position anytime something
                was (un)checked.
              </Fixed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="Android"
              version="2.2.0"
              versionUrl="https://github.com/hudl/android-framework/releases/tag/v2.2.0"
            />

            <div className={styles.changesList}>
              <Added>
                Icons, yay!
                <List>
                  <li>Effect Arrow</li>
                  <li>Effect Circle</li>
                  <li>Effect Cursor</li>
                  <li>Effect Freehand</li>
                  <li>Effect Text</li>
                </List>
              </Added>
              <Added>Utility colors for text and confirmation.</Added>
              <Improved>Icons for Add and Remove.</Improved>
              <Removed>
                Utility tint, shade and success colors. (Use text and
                confirmation instead.)
              </Removed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="iOS"
              version="5.2.2"
              versionUrl="https://github.com/hudl/ios-uniform-ui/releases/tag/5.2.2"
            />

            <div className={styles.changesList}>
              <Added>Those same effects icons.</Added>
              <Added>Utility text and confirmation.</Added>
              <Fixed>
                Toasts to include the dismiss <em>and</em> give icons the
                correct tint.
              </Fixed>
              <Improved>Add, Remove and Highlight icons.</Improved>
              <Removed>
                A few colors—namely utility tint, shade and success.
              </Removed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="5.0.0"
              versionUrl="https://github.com/hudl/rn-uniform-ui/releases/tag/v5.0.0"
            />

            <div className={styles.changesList}>
              <Added>Effect Arrow, Circle, etc., etc.</Added>
              <Added>Colors of the utility variety.</Added>
              <Added>TypeScript all the way down.</Added>
              <Improved>
                Same icons as Android and iOS. Everything’s better in threes!
              </Improved>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="Sketch Library" />

            <div className={styles.changesList}>
              <Added>
                Utility text and confirmation colors for both environments.
              </Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
